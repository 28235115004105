<template>
  <!-- 瓜分悦豆活动 -->
  <div>
    <!--  ref="openApp" :options="{ showtig: isShowShare, info: {} }" @isclose="handleShareClose" -->
    <!-- 分享提示信息弹框 -->
    <WxShareTip v-show="isShowShare" @handleClose="handleClose" />
    <div
      class="divide-bonus-activity"
      :style="{ backgroundColor: bgColor }"
    >
      <!-- 填充空白区域 -->
      <div :style="`height:${ blockHeight }rem; position: relative`">
        <div class="tip record-participation" @click="handleNavToRecord">瓜分记录</div>
        <div class="tip rules-divide" @click="handleOpenRules">活动规则</div>
      </div>
      <!-- 背景区域 -->
      <div
        class="activity-bg"
      >
        <img :src="bgUrl" alt="">
      </div>

      <!-- 活动邀请信息区域 -->
      <div class="activity-invitation">
        <!-- 邀请信息 -->
        <DivideActivityInvitation
          :id="id"
          :group-id="groupId"
          :info="activityInfo"
          :group-info="groupInfo"
          :group-status="groupStatus"
          @handleShowShare="handleShowShare"
          @handleOpenInfo="handleOpenInfo"
        />
        <!-- 邀请成功列表 -->
        <DivideRewardList
          v-if="isShowGroupPeople"
          :id="id"
          :group-info="groupInfo"
          :group-status="groupStatus"
          :info="activityInfo"
        />
      </div>
      <!-- 活动信息弹框区域-->
      <DivideActivityInformation
        :id="id"
        ref="DivideActivityInformation"
        :group-id="groupId"
        :info="activityInfo"
        :code-number="codeNumber"
        @handleDivide="handleDivide"
        @handleClose="handleCloseInfo"
      />
      <!-- 活动规则弹框区域-->
      <DivideActivityRules ref="DivideActivityRules" :rule="activityRules" />
    </div>
  </div>
</template>

<script>
import DivideActivityInvitation from './components/ActivityInvitation'
import DivideRewardList from './components/RewardList'
import DivideActivityInformation from './components/ActivityInformation'
import DivideActivityRules from './components/ActivityRules'

// 瓜分活动活动信息 默认活动底色 #fea668
// import bonusBg from '@/assets/images/activity/divide/bonus_bg.jpg'
import { Overlay, Popup } from 'vant'
import Vue from 'vue'
// import OpenApp from '@/component/GoodsDetail/openApp'
import WxShareTip from '@/component/WxShareTip'
import {
  divideBonusRules,
  fetchActiveInfoByDivide,
  fetchGroupDivideUpInfo,
  fetchGroupStatus,
  fetchPopupStatusOrGroupId
} from '@/services/activity/divide-bonus'
import { isYTApp } from '@/utils/common/validate'
import { changeURLArg, delUrlParam } from '@/utils/common/utils'
import { wxConfigInit } from '@/utils/wx-api'

Vue.use(Popup).use(Overlay)

const isYtApp = isYTApp()
export default {
  name: 'DivideBonusActivity',
  components: {
    WxShareTip,
    // OpenApp,
    DivideActivityRules,
    DivideActivityInformation,
    DivideRewardList,
    DivideActivityInvitation
  },
  data() {
    return {
      id: null, // 活动Id
      setGroupId: null, // 上次参团团Id
      groupId: null, // 团Id
      bgUrl: '', // 背景图
      blockHeight: 306 / 37.5, // 空白区域比例
      rootValue: 37.5, // 转化比例
      bgColor: '', // 默认底色 建议使用背景图最后一种底色做设置

      // 是否展示转发
      isShowShare: false,
      // 活动倒计时信息
      times: null,
      timer: null,

      // 活动基础数据
      activityInfo: {
        activityUrl: null,
        activityStarTime: null,
        activityEndTime: null,
        groupMemberNum: null,
        isExtraPrize: null,
        prizeNum: null,
        extraPrizeNum: null
      },
      // 活动开团信息
      groupStatus: 0, // 团状态
      posterImage: '', // 海报图片
      // 1. 参与人超过参团次数
      // 2  时间过期
      // 3  库存送完页面
      // 4  瓜分券活动结束
      // 5  该团已满
      // 6  瓜分券活动未开始
      groupInfo: {},
      // 分享信息
      shareInfo: {},
      codeNumber: '', // 邀请码
      activityRules: '' // 活动规则
    }
  },
  computed: {
    // 是否参团成功人数列表
    isShowGroupPeople() {
      const { groupInfo, groupStatus } = this
      return groupStatus !== 5 && groupStatus !== 6 && groupInfo.status === 1 && groupInfo.isPartake === 1
    }
  },
  created() {
    const vm = this
    const { id = null, groupId = null, reCode = '', codeNumber = '', mid = localStorage.getItem('uid'), token = localStorage.getItem('token') } = vm.$route.query
    vm.codeNumber = codeNumber
    vm.id = id
    vm.groupId = groupId
    if (isYtApp) {
      this.handleAppShare()
      window.localStorage.setItem('recode', reCode)
      if (mid && token) {
        window.localStorage.setItem('uid', mid)
        window.localStorage.setItem('token', token)
      } else {
        this.handleOpenAppLogin()
      }
    }
    this.init()
  },
  mounted() {
  },
  beforeDestroy() {
    const { timer } = this

    if (timer) {
      clearInterval(this.timer)
      this.timer = null
    }
  },
  methods: {
    /**
     * 触发打开App分享设置
     * 2021-05-28 16:31:42
     * @author SnowRock
     */
    handleAppShare() {
      this.$store.dispatch('RNrouter', {
        eventName: 'isShowShare',
        isShow: 0
      })
    },
    /**
     * 处理打开App登录
     * 2021-05-28 16:33:19
     * @author SnowRock
     */
    handleOpenAppLogin() {
      this.$store.dispatch('RNrouter', { eventName: 'login' })
    },
    /**
     * 初始化数据
     * 2021-05-25 13:52:28
     * @author SnowRock
     */
    async init() {
      // 统一配置 必须首先获取活动信息
      await this.getActiveBaseInfo()
      this.getActiveRules()
    },
    /**
     * 获取弹框是否开启状态及最新团id
     * 2021-05-28 14:23:42
     * @author SnowRock
     */
    async getPopupStatusOrGroupId() {
      const vm = this
      const { groupInfo, groupStatus } = vm
      fetchPopupStatusOrGroupId({
        mid: localStorage.getItem('uid') || '',
        group_id: vm.groupId || '',
        activity_id: vm.id
      }).then(res => {
        const { code, data } = res
        const { isPartake, surplusCt } = groupInfo
        if (code === 200) {
          const {
            is_shoot, // 是否弹框
            newest_group_id // 最新团ID
          } = data
          vm.isShoot = is_shoot
          vm.setGroupId = newest_group_id
          if (vm.groupId) {
            if (is_shoot === 1 && isPartake !== 1 && surplusCt > 0 && !groupStatus) {
              this.handleOpenInfo()
            }
          } else {
            if (is_shoot === 1) {
              this.handleOpenInfo()
            }
          }
          // localStorage.setItem('groupId', vm.groupId || '')
        }
      })
    },
    /**
     * 获取活动基本信息
     * 2021-05-25 11:49:45
     * @author SnowRock
     */
    async getActiveBaseInfo() {
      const vm = this
      const params = {
        id: this.id,
        mid: localStorage.getItem('uid'),
        groupId: vm.groupId || ''
      }
      fetchActiveInfoByDivide(params).then(async res => {
        const { code, data } = res

        if (code === 200) {
          const {
            activity_name, // 活动名称
            activity_url, // 活动跳转地址
            group_member_num, // 成团人数量
            is_extra_prize, // 是否开启新用户额外奖励（1是、0否）
            prize_num, // 奖品数量
            extra_prize_num, // 额外奖励数量
            background_color, // 背景色
            share_title, // 分享标题
            share_icon, // 分享图标
            share_copywriting, // 分享文案
            activity_startime, // 活动开始时间
            activity_endtime, // 活动结束时间
            parttake_ct, // 参与次数（0不限制）
            status, // 活动状态（0 活动中、1
            // 参与人超过参团次数、2  时间过期、3  库存送完页面、4  瓜分券活动结束、5  该团已满、6  瓜分券活动未开始）
            background_picture // 背景图
          } = data
          document.title = activity_name
          vm.bgUrl = background_picture
          vm.bgColor = background_color

          vm.shareInfo = {
            title: share_title, // 分享标题
            desc: share_copywriting, // 分享描述
            imgUrl: share_icon // 分享图标
          }
          // 活动基础数据
          vm.activityInfo = {
            activityUrl: activity_url,
            groupMemberNum: group_member_num,
            parttakeCt: parttake_ct,
            isExtraPrize: is_extra_prize,
            prizeNum: prize_num,
            activityStarTime: activity_startime,
            activityEndTime: activity_endtime,
            extraPrizeNum: extra_prize_num,
            status: vm.groupId ? 0 : status // 活动状态
          }

          // 需要进行判断当前是否是否参团或者开团
          if (vm.groupId) {
            // 参团 需要获取已参团信息
            await this.getGroupInfo(status)
          } else {
            if (status === 0) {
              // 统一配置 必须首先获取弹框状态
              await this.getPopupStatusOrGroupId()
            }
          }
          if (!isYtApp) {
            await vm.handleShowShare(false)
          }
        }
      })
    },

    /**
     * 获取成团信息
     * 2021-05-25 14:13:47
     * @author SnowRock
     */
    async getGroupInfo(aStatus) {
      const params = {
        id: this.groupId,
        mid: localStorage.getItem('uid'),
        activity_id: this.id
      }
      try {
        const res = await fetchGroupDivideUpInfo(params)
        const { code, data } = res
        if (Number(code) === 200) {
          const { is_group, is_partake, prize_num, tuan_endtime, unusual_status, surplus_ct, poster_img, user_list, status, extra_prize_num } = data

          this.groupStatus = unusual_status
          this.posterImage = poster_img
          this.groupInfo = {
            isGroup: is_group,
            surplusCt: surplus_ct,
            countDown: tuan_endtime,
            isPartake: is_partake,
            prizeNum: prize_num,
            status: status,
            userList: user_list,
            extraPrizeNum: extra_prize_num
          }

          if (aStatus === 0) {
            // 统一配置 必须首先获取弹框状态
            await this.getPopupStatusOrGroupId()
          }
        }
      } catch (e) {
        console.log(e)
      }
    },

    /**
     * 点击参团回调更新数据
     */
    handleDivide(groupId) {
      if (groupId) {
        this.groupId = groupId
      }
      this.init()
    },
    /**
     * 获取成团信息
     * 2021-05-25 14:13:47
     * @author SnowRock
     */
    async getGroupStatus() {
      const params = {
        group_id: this.groupId || '',
        activity_id: this.id
      }
      fetchGroupStatus(params).then(res => {
        const { code, data } = res
        if (Number(code) === 200) {
          const { status } = data

          this.groupStatus = status
        }
      })
    },
    /**
     * 获取活动规则
     * 2021-05-25 14:13:47
     * @author SnowRock
     */
    getActiveRules() {
      const params = {
        id: this.id
      }
      divideBonusRules(params).then(res => {
        const { code, data } = res
        if (Number(code) === 200) {
          const { activity_desc } = data
          this.activityRules = activity_desc.replaceAll('\n', '<br/>').replaceAll('\r', '<br/>')
        }
      })
    },
    /**
     * 处理打开活动详情
     * 2021-05-24 10:00:48
     * @author SnowRock
     */
    handleOpenInfo() {
      this.$nextTick(() => {
        this.$refs.DivideActivityInformation && this.$refs.DivideActivityInformation.handleOpenInfo()
      })
    },
    /**
     * 处理打开活动规则
     * 2021-05-24 10:00:48
     * @author SnowRock
     */
    handleOpenRules() {
      this.$refs.DivideActivityRules.handleOpenRules()
    },
    /**
     * 处理打开分享弹窗
     * 2021-05-24 10:00:48
     * @author SnowRock
     */
    async handleShowShare(isShare) {
      const uid = localStorage.getItem('uid')
      const reCode = localStorage.getItem('recode') // 邀请码 既是codeNumber又是recode
      if (!uid) {
        if (isYtApp) {
          this.handleOpenAppLogin()
        } else {
          this.$router.replace('/login?backUrl=' + encodeURIComponent(encodeURIComponent(window.location.href)))
        }
        return
      }
      const url = window.location.href
      const shareInfo = {
        ...this.shareInfo,
        link: changeURLArg(changeURLArg(delUrlParam(delUrlParam(url, 'mid'), 'token'), 'groupId', this.groupId || ''), 'codeNumber', reCode || '')
      }
      if (isYtApp) {
        const appShare = {
          eventName: 'share_cake',
          image: shareInfo.imgUrl, // 分享标题
          title: shareInfo.title,
          des: shareInfo.desc,
          url: shareInfo.link,
          posterText: `邀请${this.activityInfo.groupMemberNum - 1}位好友即可瓜分${this.activityInfo.prizeNum}悦豆`,
          posterImage: this.posterImage
        }
        this.$store.dispatch('RNrouter', appShare)
      } else {
        wxConfigInit(shareInfo)
        // await this.$store.dispatch('wxConfigInit', shareInfo)
        this.isShowShare = isShare
      }
    },
    handleClose() {
      this.isShowShare = false
    },
    handleCloseInfo() {
      // if (!this.groupId) {
      //   this.groupId = this.setGroupId
      //   this.init()
      // }
    },
    /**
     * 处理打开瓜分记录
     * 2021-05-24 10:00:48
     * @author SnowRock
     */
    handleNavToRecord() {
      const { id = '' } = this
      this.$router.push({
        path: '/ParticipationRecord',
        query: {
          id: id
        }
      })
    }
  }
}
</script>

<style lang="less">
.divide-bonus-activity {
  .invitation-title {
    width: 192px;
    height: 35px;
    border-radius: 8px;
    margin: -16px auto 0;

    &.be-success {
      background-image: url("~@/assets/images/activity/divide/be_success.png");
    }

    &.invitation-friend {
      background-image: url("~@/assets/images/activity/divide/invitation_friend.png");
    }

    &.reward-list {
      background-image: url("~@/assets/images/activity/divide/reward_list.png");
    }

    background-size: contain;
    background-repeat: no-repeat;
  }

  .activity-btn {
    width: 262px;
    height: 44px;
    background-color: transparent;
    background-image: url("~@/assets/images/activity/divide/activity_btn.png");
    background-repeat: no-repeat;
    background-size: contain;
    color: #fff;
    font-size: 14px;
    line-height: 20px;
    box-shadow: none;
    border: none;

    img {
      width: 22px;
      height: 18px;
      margin-right: 7px;
      vertical-align: middle;
      display: inline-block;
    }

    span {
      vertical-align: middle;
      display: inline-block;
    }

    & + .activity-btn {
      margin-top: 5px;
    }
  }
}
</style>
<style lang="less" scoped>
.divide-bonus-activity {
  min-height: 100vh;
  position: relative;
  padding-bottom: 1px;
  padding-top: 1px;
  box-sizing: border-box;

  .activity-bg {
    width: 100%;
    height: auto;
    position: absolute;
    z-index: 0;
    top: -1px;
    left: 0;

    &.share {
      //top: 53px
    }

    img {
      width: 100%;
      height: auto;
    }
  }

  .tip {
    position: absolute;
    width: 67px;
    height: 26px;
    line-height: 26px;
    top: 19px;
    background: #feb23e;
    color: #ffffff;
    font-size: 14px;
    text-align: center;
    z-index: 3;
    box-sizing: border-box;
  }

  .record-participation {
    left: 0;
    border-radius: 0 26px 26px 0;
  }

  .rules-divide {
    right: 0;
    padding-left: 10px;
    border-radius: 26px 0 0 26px;
  }

  .activity-invitation {
    z-index: 2;
    position: relative;
  }
}
</style>
