<template>
  <div class="divide-reward">
    <!-- 邀请信息title -->
    <div class="invitation-title reward-list" />

    <!-- 邀请人列表 -->
    <div class="divide-reward-list">
      <div v-for="(i, j) in (groupInfo.userList || [])" :key="j" class="reward-info">
        <div class="info-head-portrait">
          <img :src="i.litpic" alt="头像">
        </div>
        <div class="info-name">{{ i.nickname }}</div>
        <div class="info-reward">
          {{ i.prize_num }}悦豆
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'DivideRewardList',
  props: {
    id: {
      type: null,
      default: null
    },
    info: {
      type: Object,
      default: () => {
        return {
          activityUrl: null,
          groupMemberNum: null,
          isExtraPrize: null,
          prizeNum: null,
          extraPrizeNum: null
        }
      }
    },
    groupInfo: {
      type: null,
      default: () => {
        return {
          isGroup: null,
          surplusCt: null,
          countDown: null,
          isPartake: null,
          prizeNum: null,
          status: null,
          userList: null,
          extraPrizeNum: null
        }
      }
    },
    groupStatus: {
      type: null,
      default: null
    }
  },
  data() {
    return {
    }
  }
}
</script>

<style lang="less" scoped>
.divide-reward {
  width: 349px;
  opacity: 1;
  border-radius: 11px;
  background: #fff;
  padding-top: 1px;
  padding-bottom: 20px;
  margin: 0px auto 30px;

  .divide-reward-list {
    width: calc(100% - 44px);
    margin: 17px auto 0;

    .reward-info {
      width: 100%;
      display: flex;
      align-items: center;
      font-size: 14px;
      color: #000000;
      margin-bottom: 15px;
      &:last-of-type {
        margin-bottom: 10px;
      }

      .info-head-portrait {
        width: 34px;
        height: 34px;
        border-radius: 50%;
        margin-right: 14px;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
        }
      }

      .info-name {
        flex: auto;
        width: auto;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .info-reward {
        flex: 100px;
        width: 100px;
        text-align: right;
      }
    }
  }
}
</style>
