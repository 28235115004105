<template>
  <van-overlay :show="popupVisible" class="popup-divide">
    <div class="popup-divide-content" @click.stop>
      <div class="divide-activity-info">
        <!-- 提示信息 -->
        <div class="activity-title">还差{{ lostCount }}人即可开启</div>
        <div class="activity-content">
          <div class="content">{{ rewardNum }}</div>
          <div class="unit">悦豆大奖</div>
        </div>
        <!-- 悦豆按钮 -->
        <div class="activity-divide-btn" @click="handleDivide" />

        <div class="close-popup" @click="handleClose" />
      </div>
    </div>
  </van-overlay>
</template>

<script>
import { invitationPeople, openGroupDivide } from '@/services/activity/divide-bonus'
import { isYTApp } from '@/utils/common/validate'

const isYtApp = isYTApp()
export default {
  name: 'DivideActivityInformation',
  components: {
  },
  props: {
    id: {
      type: null,
      default: null
    },
    groupId: {
      type: null,
      default: null
    },
    codeNumber: {
      type: null,
      default: null
    },
    info: {
      type: Object,
      default: () => {
        return {
          activityUrl: null,
          groupMemberNum: null,
          isExtraPrize: null,
          prizeNum: null,
          extraPrizeNum: null
        }
      }
    }
  },
  data() {
    return {
      popupVisible: false
    }
  },
  computed: {
    lostCount() {
      if (!(this.info && this.info.groupMemberNum)) return 0
      return this.info && this.info.groupMemberNum && (this.info.groupMemberNum - 1)
    },
    rewardNum() {
      if (!(this.info && this.info.prizeNum)) return 0
      return this.info && this.info.prizeNum
    }
  },
  methods: {
    handleClose() {
      this.popupVisible = false
      this.$emit('handleClose')
    },
    handleDivide() {
      this.popupVisible = false
      const uid = localStorage.getItem('uid')
      if (!uid) {
        if (isYtApp) {
          this.handleOpenAppLogin()
        } else {
          this.$router.replace('/login?backUrl=' + encodeURIComponent(encodeURIComponent(window.location.href)))
        }
        return
      }
      this.handleDivideInfo()
    },
    /**
     * 参团
     * 2021-05-25 15:18:37
     * @author SnowRock
     */
    async handleDivideInfo() {
      const { groupId, id } = this

      if (groupId) {
        // 参团
        invitationPeople({
          activity_id: id,
          group_id: groupId
        }).then(res => {
          const { code = null } = res
          if (code === 200) {
            this.$emit('handleDivide')
          } else {
            this.$emit('handleDivide')
          }
        })
      } else {
        // 开团
        openGroupDivide({
          activity_id: id
        }).then(res => {
          const { code = null, data } = res
          if (code === 200) {
            this.$emit('handleDivide', data.group_id)
          } else {
            this.$emit('handleDivide')
          }
        })
      }
    },
    handleOpenInfo() {
      this.popupVisible = true
    }
  }
}
</script>

<style lang="less">
.popup-divide {
  z-index: 999
}
</style>

<style lang="less" scoped>
.popup-divide-content {
  padding-bottom: 37px;
  position: relative;
  left: 50%;
  top: 50%;
  margin-left: -158px;
  margin-top: -164px;
}
.divide-activity-info {
  width: 317px;
  height: 329px;
  text-align: center;
  background-repeat: no-repeat;
  position: relative;
  padding-top: 1px;
  overflow-y: inherit !important;
  background-size: cover;
  background-image: url("~@/assets/images/activity/divide/d_a_i_bg.png");

  .activity-title {
    width: calc(100% - 2px);
    margin-top: 38px;
    line-height: 19px;
    color: #ff3233;
    font-size: 20px;
    font-family: PingFang SC, PingFang SC-Regular,sans-serif;
  }

  .activity-content {
    margin-top: 33px;
    color: #ff3233;
    width: calc(100% - 2px);
    .content {
      vertical-align: middle;
      display: inline-block;
      font-size: 58px;
      font-weight: bold;
      margin-right: 7px;
      font-family: Source Han Sans CN, Source Han Sans CN-Bold,sans-serif;
    }
    .unit {
      width: 23px;
      height: auto;
      font-size: 12px;
      display: inline-block;
      font-family: PingFang SC, PingFang SC-Medium, sans-serif;
      vertical-align: middle;
    }
  }
}
.activity-divide-btn {
  width: 215px;
  height: 44px;
  margin: 74px auto 0;
}
.close-popup {
  width: 30px;
  height: 30px;
  position: absolute;
  bottom: -37px;
  left: 50%;
  margin-left: -15px;
  background-repeat: no-repeat;
  background-image: url("~@/assets/images/activity/divide/close_popup_icon.png");
  background-size: contain;
  z-index: 99;
}
</style>
