<template>
  <van-popup v-model="popupVisible" class="popup-rules" :lock-scroll="true" :close-on-click-overlay="false">
    <div class="popup-rules-content" @click.stop>

      <!-- 规则顶部信息-->
      <div class="rules-title">活动规则</div>

      <!-- 活动规则内容-->
      <div class="rules-content" v-html="rule" />

      <!-- 活动规则内容-->
      <div class="btn-content">
        <button class="activity-btn" @click="handleClose">
          我知道了
        </button>
      </div>
    </div>
  </van-popup>
</template>

<script>
import { Popup } from 'vant'
import Vue from 'vue'

Vue.use(Popup)
export default {
  name: 'DivideActivityRules',
  components: {
  },
  props: {
    rule: {
      type: null,
      default: ''
    }
  },
  data() {
    return {
      popupVisible: false
    }
  },
  methods: {
    handleClose() {
      this.popupVisible = false
    },
    handleOpenRules() {
      this.popupVisible = true
    }
  }
}
</script>

<style lang="less">
.popup-rules {
  width: 100%;
  z-index: 999;
  background-color: transparent!important;
}
</style>

<style lang="less" scoped>
.popup-rules-content {
  width: calc(100% - 44px);
  height: 298px;
  opacity: 1;
  background: #ffffff;
  border-radius: 5pt;
  padding: 22px;
  margin: 0 auto;
  box-sizing: border-box;

  .rules-title {
    font-size: 15px;
    line-height: 16px;
    margin-bottom: 22px;
  }
  .rules-content {
    overflow-y: scroll;
    min-height: 155px;
    max-height: 155px;
    margin-bottom: 23px;
    text-align: left;
  }
  .btn-content {
    margin-bottom: 10px;
  }
}
</style>
