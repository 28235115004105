/**
 * 瓜分红包
 * 2021-05-25 11:29:45
 * @author SnowRock
 */
import request from '@/services/request'

/**
 * 开团或者参团
 * 2021-05-25 11:36:16
 * @author SnowRock
 * @param params
 * @returns {Promise<*|undefined>}
 */
export function openGroupDivide(params) {
  return request({
    url: '/activity/gf/activateCard',
    method: 'post',
    data: params,
    prefix: 'yuelvhui'
  })
}

/**
 * 活动基本信息
 * 2021-05-25 11:36:16
 * @author SnowRock
 * @param params
 * @param params.id 活动id
 * @param params.groupId 团id
 * @param params.mid 用户id
 * @returns {Promise<*|undefined>}
 */
export function fetchActiveInfoByDivide(params) {
  return request({
    url: `/activity/gf/activityData/${params.id}?mid=${params.mid}&groupId=${params.groupId}`,
    method: 'get',
    prefix: 'yuelvhui'
  })
}

/**
 * 参加团接口
 * 2021-05-25 11:36:16
 * @author SnowRock
 * @param params
 * @param params.activity_id 活动id
 * @param params.group_id   团id
 * @returns {Promise<*|undefined>}
 */
export function invitationPeople(params) {
  return request({
    url: `/activity/gf/invitationMenber?activity_id=${params.activity_id}&group_id=${params.group_id}`,
    method: 'get',
    data: params,
    prefix: 'yuelvhui'
  })
}

/**
 * 团瓜分信息
 * 2021-05-25 11:36:16
 * @author SnowRock
 * @param params
 * @param params.id 团Id
 * @param params.mid 用户id
 * @returns {Promise<*|undefined>}
 */
export function fetchGroupDivideUpInfo(params) {
  return request({
    url: `/activity/gf/groupData/${params.id}?mid=${params.mid}`,
    method: 'get',
    data: params,
    prefix: 'yuelvhui'
  })
}

/**
 * 活动规则
 * 2021-05-25 11:36:16
 * @author SnowRock
 * @param params
 * @param params.id 活动id
 * @returns {Promise<*|undefined>}
 */
export function divideBonusRules(params) {
  return request({
    url: `/activity/gf/activityRule/${params.id}`,
    method: 'get',
    data: params,
    prefix: 'yuelvhui'
  })
}

/**
 * 获取活动弹框及最新团Id的信息
 * 2021-05-28 14:21:44
 * @author SnowRock
 * @param params
 * @param params.mid 用户id
 * @param params.activity_id 活动id
 * @param params.group_id 团id
 * @returns {Promise<*|undefined>}
 */
export function fetchPopupStatusOrGroupId(params) {
  return request({
    url: `/activity/gf/enter?activity_id=${params.activity_id}&mid=${params.mid}&group_id=${params.group_id}`,
    method: 'get',
    prefix: 'yuelvhui'
  })
}

/**
 * 瓜分记录
 * 2021-05-25 11:36:16
 * @author SnowRock
 * @param params
 * @param params.type
 * @param params.page
 * @param params.pagesize
 * @returns {Promise<*|undefined>}
 */
export function fetchRewardRecordList(params) {
  return request({
    url: `/activity/gf/activityRecordList?type=${params.type}&page=${params.page}&pagesize=${params.pagesize}`,
    method: 'get',
    data: params,
    prefix: 'yuelvhui'
  })
}

/**
 * 开团或者参团
 * 2021-05-25 11:36:16
 * @author SnowRock
 * @param params
 * @param params.group_id 团id
 * @param params.activity_id 活动id
 * @returns {Promise<*|undefined>}
 */
export function fetchGroupStatus(params) {
  return request({
    url: `/activity/gf/getActivityStatus?group_id=${params.group_id}&activity_id=${params.activity_id}`,
    method: 'get',
    data: params,
    prefix: 'yuelvhui'
  })
}

/**
 * 悦豆促销列表
 * 2021-06-03 16:05:11
 * @author SnowRock
 * @param params 入参
 * @param params.page 页码
 * @param params.pageSize 页数
 * @returns {Promise<*|undefined>}
 */
export function fetchDivideBonus(params) {
  return request({
    url: `/mall/getPromoteSale`,
    method: 'post',
    data: params,
    prefix: 'yuelvhui'
  })
}
