<template>
  <div class="divide-activity-invitation">
    <!-- 邀请信息title -->
    <div class="invitation-title invitation-friend" />
    <!-- 邀请总人数 -->
    <div v-if="!(groupStatus !== 5 && groupStatus !== 6 && info.status !== 5 && info.status !== 6 && status === 1 && isPartake === 1) && info.groupMemberNum" class="invitation-people">
      <!-- 人数区域 -->
      <div
        class="peoples-content"
        :class=" info.groupMemberNum > 5 ? 'max' : info.groupMemberNum === 5 ? 'middle' : ''"
        :style="{
        }"
      >
        <div
          v-for="(i, key) in info.groupMemberNum"
          :key="i"
          class="people-info"
          :style="{
            marginRight: info.groupMemberNum > 5 ? (-((info.groupMemberNum - 5) / 5 * 22 / 37.5) + 'rem') : ''
          }"
        >
          <img :src="(userList || [])[key] ? userList[key].litpic : lostIcon" alt="头像">
        </div>
      </div>
    </div>

    <!-- 内容区域 -->
    <div class="tips-content">
      <p v-if="groupStatusByNot && status === 0 && isPartake === 1">还差{{ surplusCt }}人即可开启，快去邀请好友吧！</p>
      <p v-if="groupStatusByNot && status === 0 && info.isExtraPrize === 1 && isPartake === 1">邀请新用户额外奖励{{ extraPrizeNum }}豆</p>
      <!-- 参与人超过参团次数 -->
      <p v-if="groupStatus === 1 || info.status === 1">抱歉，只能参与{{ info.parttakeCt }}次瓜分红包活动</p>
      <!-- 时间过期 -->
      <p v-if="groupStatus === 2 || info.status === 2">已失效，未能在规定时间内开启红包</p>
      <!-- 库存完毕 -->
      <p v-if="groupStatus === 3 || info.status === 3">抱歉，红包已经发放完啦，下次早点来哦！</p>
      <!-- 活动结束 -->
      <p v-if="groupStatus === 4 || info.status === 4">活动已经结束了，下次早点来哦！</p>
      <!-- 该团已满 -->
      <p v-if="groupStatus === 5 || info.status === 5">来晚了，该红包已经被瓜分干净啦！！</p>
      <p v-if="groupStatus === 6 || info.status === 6">
        活动还没开始，等开始了再来吧！
        <br>
        开始时间：{{ info.activityStarTime }}
      </p>
    </div>
    <!-- 瓜分成功信息区域 -->
    <div v-if="groupStatus !== 5 && groupStatus !== 6 && info.status !== 5 && info.status !== 6 && status === 1 && isPartake === 1" class="invitation-success">
      <p><span class="total-num">{{ prizeNum }}</span><span>悦豆</span></p>
    </div>
    <!-- 活动倒计时 -->
    <div v-if="groupStatusByNot && status === 0 && countDown && time" class="activity-time">
      <p>
        距活动结束还剩{{ `${time.h}:${time.m}:${time.s}` }}
      </p>
    </div>
    <!-- 按钮区域 -->
    <div class="activity-btn-content">
      <button v-if="info.status === 0 && ((groupStatusByNot && status === 0) || !(groupId && groupId !== ''))" class="activity-btn" @touchstart.stop="handleShare">
        <img :src="wxIcon">
        <span>邀请微信好友一起瓜分</span>
      </button>
      <!-- 如果团状态为 参与人超过参团次数|| 时 展示查看我的活动 || groupStatus === 7 开团中或者 -->
      <button v-if="groupStatus === 1 || info.status === 1" class="activity-btn" @click="handleNavToRecord">
        查看我的活动
      </button>
      <button v-if="status === 1 && isPartake === 1 && (info.activityUrl && info.activityUrl !== '')" class="activity-btn" @click="handleNavToActive">
        立即使用
      </button>
      <button
        v-if="groupStatus === 2 || groupStatus === 5 || info.status === 2 || info.status === 5 || (status === 1 && isPartake === 1) || (info.status === 0 && info.parttakeCt && info.parttakeCt > 0)"
        class="activity-btn"
        @click="handleNavToNew"
      >
        瓜分新红包
      </button>
      <button v-if="groupStatus === 3 || groupStatus === 4 || groupStatus === 6 || info.status === 3 || info.status === 4 || info.status === 6" class="activity-btn" @click="handleNavToHome">
        随便逛逛
      </button>
    </div>
  </div>
</template>

<script>
import wxIcon from '@/assets/images/activity/divide/wx_icon.png'
import lostIcon from '@/assets/images/activity/divide/lost_people.png'
import { countTime } from '@/utils/public'
import { isYTApp, isWeChat } from '@/utils/common/validate'

export default {
  name: 'DivideActivityInvitation',
  props: {
    id: {
      type: null,
      default: null
    },
    groupId: {
      type: null,
      default: null
    },
    info: {
      type: Object,
      default: () => {
        return {
          status: null,
          parttakeCt: null,
          activityUrl: null,
          activityStarTime: null,
          activityEndTime: null,
          groupMemberNum: null,
          isExtraPrize: null,
          prizeNum: null,
          extraPrizeNum: null
        }
      }
    },
    groupInfo: {
      type: Object,
      default: () => {
        return {
          isGroup: null,
          surplusCt: null,
          countDown: null,
          isPartake: null,
          prizeNum: null,
          status: null,
          userList: null,
          extraPrizeNum: null
        }
      }
    },
    groupStatus: {
      type: null,
      default: null
    }
  },
  data() {
    return {
      wxIcon,
      lostIcon,

      isWx: isWeChat(),
      // 活动倒计时信息
      time: null,
      times: null,
      isGroup: null,
      surplusCt: null,
      countDown: null,
      isPartake: null,
      prizeNum: null,
      status: null,
      userList: [],
      extraPrizeNum: null,
      timer: null
    }
  },
  computed: {
    groupStatusByNot() {
      const notList = [1, 2, 3, 4, 5, 6]
      return notList.findIndex(i => i === this.groupStatus) <= -1
    }
  },
  watch: {
    'countDown'(val) {
      if (val) {
        if (val - (new Date().getTime() / 1000) <= 86400) {
          this.countTime(val)
        }
      }
    },
    'groupInfo'({
      isGroup,
      surplusCt,
      isPartake,
      prizeNum,
      status,
      userList,
      countDown,
      extraPrizeNum
    }) {
      this.isGroup = isGroup
      this.surplusCt = surplusCt
      this.isPartake = isPartake
      this.prizeNum = prizeNum
      this.status = status
      this.userList = userList || []
      this.extraPrizeNum = extraPrizeNum
      this.countDown = countDown || null
    }
  },
  created() {
    this.groupInfoInsert = this.groupInfo
  },
  beforeDestroy() {
    const { timer } = this

    if (timer) {
      clearInterval(this.timer)
      this.timer = null
    }
  },
  methods: {
    /**
     * 倒计时
     * 2021-05-24 14:50:47
     * @author SnowRock
     * @param endTime 倒计时信息
     */
    countTime(endTime) {
      const vm = this
      if (!this.groupStatusByNot && endTime <= (new Date().getTime() / 1000)) {
        if (vm.timer) {
          clearInterval(vm.timer)
          vm.timer = null
        }
      } else {
        vm.time = countTime(endTime)
        vm.timer = setTimeout(() => {
          vm.countTime(endTime)
        }, 1000)
      }
    },

    /**
     * 处理打开App登录
     * 2021-05-28 16:33:19
     * @author SnowRock
     */
    handleOpenAppLogin() {
      this.$store.dispatch('RNrouter', { eventName: 'login' })
    },
    /**
     * 处理打开瓜分记录
     * 2021-05-24 10:00:48
     * @author SnowRock
     */
    handleNavToRecord() {
      const { id = '' } = this
      this.$router.push({
        path: '/ParticipationRecord',
        query: {
          id: id
        }
      })
    },
    /**
     * 回到首页 随便逛逛
     */
    handleNavToHome() {
      if (isYTApp()) {
        // 悦淘App方法 跳回首页
        const appShare = {
          path: 'router://MainApp'
        }
        this.$store.dispatch('RNrouter', appShare)
      } else {
        this.$router.replace({
          path: '/',
          query: {
          }
        })
      }
    },
    /**
     * 瓜分新的红包
     */
    handleNavToNew() {
      localStorage.removeItem('groupId')
      window.location.href = '/DivideBonusActivity?id=' + this.id + '&t=' + Date.now()
    },
    /**
     * 立即使用页面
     */
    handleNavToActive() {
      const { activityUrl } = this.info

      if (activityUrl.indexOf('http') > -1) {
        window.location.href = activityUrl
      } else {
        this.$router.push({
          path: activityUrl || '/',
          query: {}
        })
      }
    },

    handleShare() {
      if (this.groupId) {
        this.$emit('handleShowShare', true)
      } else {
        this.$emit('handleOpenInfo')
      }
    }
  }
}
</script>

<style lang="less" scoped>
.divide-activity-invitation {
  width: 349px;
  opacity: 1;
  border-radius: 11px;
  background: #fff;
  padding-top: 1px;
  padding-bottom: 20px;
  margin: 0 auto 30px;

  .invitation-people {
    width: 100%;
    margin: 20px auto;

    .peoples-content {
      width: 249px;
      margin: 0px auto;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      position: relative;

      &.middle {
        justify-content: space-between;

        .people-info {
          margin-right: 0;
        }
      }

      .people-info {
        width: 44px;
        height: 44px;
        border-radius: 50%;
        background: #FBB33D;
        overflow: hidden;
        margin-right: 22px;

        &:last-of-type {
          margin-right: 0;
        }

        img {
          width: 100%;
          height: 100%;
        }
      }
      &.max {
        display: block;
        .people-info {
          display: inline-block;
          box-shadow: 0 0 0 1px #fff;
        }
      }
    }
  }

  .invitation-success {
    font-size: 14px;
    line-height: 17px;
    color: #5a2e09;
    margin-top: 42px;
    margin-bottom: 40px;

    p {
      span {
        font-weight: 400;
        font-size: 19px;
        font-family: PingFang SC, PingFang SC-Regular, sans-serif;
        vertical-align: bottom;

        &.total-num {
          line-height: 32px;
          font-size: 36px;
          margin-right: 9px;
        }
      }
    }
  }

  .tips-content {
    font-size: 14px;
    line-height: 17px;
    color: #5a2e09;
    font-family: PingFang SC, PingFang SC-Regular, sans-serif;
    font-weight: 400;
    margin-bottom: 10px;

    p {
      margin-bottom: 8px;
    }
    p:last-of-type {
      margin-bottom: 28px;
    }
  }

  .activity-time {
    font-size: 16px;
    line-height: 16px;
    margin-bottom: 10px;
    color: #F7263C;
  }

  .activity-btn-content {
  }
}
</style>
