<template>
  <div class="share-tip">
    <div class="arrow">
      <img src="../../assets/images/right_top_arrow.png" alt="">
    </div>
    <div class="tip-content">
      <div class="tip-text">点击右上角按钮，然后在弹窗的菜单中， 点击“发送给朋友”即可分享哦~</div>
      <div class="tip-btn" @click="handleClose">
        <img src="../../assets/images/konwed_icon.png" alt="">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WxShareTip',
  components: {
  },
  data() {
    return {
    }
  },
  mounted() {
  },
  methods: {
    handleClose() {
      this.$emit('handleClose')
    }
  }
}
</script>

<style lang="less" scoped>
.share-tip {
  width: 100vh;
  height: 100vh;
  position: fixed;
  z-index: 99;
  background-color: rgba(0, 0, 0, 0.6);

  .arrow {
    position: fixed;
    right: 16px;
    top: 19px;
    width: 141px;
    height: 171px;

    img {
      width: 100%;
      height: 100%;
    }
  }
  .tip-content {
    position: fixed;
    top: 207px;
    left: 50%;
    width: 260px;
    margin-left: -130px;
    font-family: PingFang SC, sans-serif;
    color: #ffffff;
    font-size: 15px;
    line-height: 18px;
    .tip-text {
      margin-bottom: 12px;
    }

    .tip-btn {
      width: 102px;
      height: 43px;
      margin: 0 auto;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>
